import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "date": "2019-09-28T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <blockquote>
      <p parentName="blockquote">{`Your company is a manifestation of it's leader`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`All your faults magnify, as do all your positives`}</p>
    </blockquote>
    <p>{`— Haraldur Thorleifsson, Ueno `}<a parentName="p" {...{
        "href": "https://designdetails.fm/episodes/308804"
      }}>{`Design Details episode 314`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      